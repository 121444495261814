import React, { useEffect } from "react";
import "./blog-page.css";
import Footer from "../../components/Footer/footer";
import { Header } from "../../components/Header/header";
import Container from "../../components/container/container";

import { useTranslation } from "react-i18next";

const WidgetComponent = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.defer = true;
    script.setAttribute("data-use-service-core", "");
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    // Создаем наблюдатель за изменениями внутри блока виджета
    const blockElement = document.querySelector(
      ".elfsight-app-82cc895e-ff74-4097-877a-c06c531cce84"
    );

    if (blockElement) {
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.addedNodes.length > 0) {
            const linkElements = blockElement.querySelectorAll("a");
            linkElements.forEach((linkElement) => {
              if (linkElement.href.includes("elfsight.com")) {
                // Удаляем элемент, если условие выполняется
                linkElement.remove();
              }
            });
          }
        }
      });

      // Начинаем наблюдение за изменениями внутри блока виджета
      observer.observe(blockElement, { childList: true, subtree: true });

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <>
      <div className="elfsight-app-82cc895e-ff74-4097-877a-c06c531cce84"></div>
    </>
  );
};

const BlogPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div className="blog-page">
        <Container>
          <div className="blog-page__links">
            {t("links")}:
            <br />
            <br />
            <a
              href="https://www.facebook.com/profile.php?id=61550614133164"
              target="_blank"
              rel="noreferrer"
            >
              {t("authorName")}
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61550614133164"
              target="_blank"
              rel="noreferrer"
            >
              {t("newsGroup")}
            </a>
          </div>
          <WidgetComponent />
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default BlogPage;
