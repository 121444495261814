import "./second-step.css";
import Container from "../../../components/container/container";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SecondStep = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      offset: 0,
      duration: 600,
      easing: "ease-in-sine",
      delay: 100,
    });
    AOS.refresh();
  }, []);
  return (
    <div className="second-step">
      <Container>
        <div className="second-step__title">
          <i>{t("SS_infoTitle")}</i>
        </div>
        <div className="second-step__pictures-block">
          <div className="second-step__pictures-block__one-p" data-aos="fade">
            <Link to="/art/23">
              <img src="images/23.jpg" alt="Land and Sea." />
            </Link>
          </div>
          <div className="second-step__pictures-block__two-p" data-aos="fade">
            <Link to="/art/17">
              <img src="images/17.jpg" alt="Meganome. Kingdom of cyclops." />
            </Link>
          </div>
          <div className="second-step__pictures-block__three-p" data-aos="fade">
            <Link to="/art/19">
              <img src="images/19.jpg" alt="King Echkidagh." />
            </Link>
          </div>
          <div className="second-step__pictures-block__four-p" data-aos="fade">
            <Link to="/art/18">
              <img src="images/18.jpg" alt="Vanished civilizations." />
            </Link>
          </div>
          <div className="second-step__pictures-block__five-p" data-aos="fade">
            <Link to="/art/20">
              <img src="images/20.jpg" alt="Kiik-Atlama." />
            </Link>
          </div>
          <div className="second-step__pictures-block__six-p" data-aos="fade">
            <Link to="/art/21">
              <img src="images/21.jpg" alt="Hymn to the sun. Sunrise." />
            </Link>
          </div>
          <div className="second-step__pictures-block__seven-p" data-aos="fade">
            <Link to="/art/22">
              <img src="images/22.jpg" alt="Returning home." />
            </Link>
          </div>
          <div className="second-step__pictures-block__eight-p" data-aos="fade">
            <Link to="/art/16">
              <img src="images/16.jpg" alt="Light contrasts. Silent witness." />
            </Link>
          </div>
        </div>
        <div className="second-step__button">
          <Link to={"/art"}>{t("seeMoreButton")}</Link>
        </div>
      </Container>
    </div>
  );
};

export default SecondStep;
