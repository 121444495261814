// import Container from "../../../components/container/container";
import "./fourth-step.css";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

// import { useTranslation } from "react-i18next";

const FourthStep = () => {
  // const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      offset: 200,
      easing: "ease-in-sine",
      delay: 200,
    });
    AOS.refresh();
  }, []);
  return (
    <div className="fourth-step">
      {/* <Container>
        <div className="fourth-step__content">
          <div className="fourth-step__content__text">
            {t("FoS_content1")}
            <br />
            {t("FoS_content2")}
            <br />
            {t("FoS_content3")}
            <br /> 
            {t('FoS_content4')} 
            <br />
            {t('FoS_content5')}
          </div>
        </div>
      </Container> */}
      <div className="fourth-step__bg">
        <div
          className="fourth-step__bg__mountins"
          data-aos="fade"
          data-aos-duration="700"
          data-aos-delay="100"
        ></div>
      </div>
    </div>
  );
};

export default FourthStep;
