import React, { useEffect, useState } from "react";
import { Element, scroller } from "react-scroll";
import Scroll from "../../components/scroll/scroll";
import FirstStep from "./firstStep/firstStep";
import SecondStep from "./secondStep/secondStep";
import ThirdStep from "./thirdStep/thirdStep";
import FourthStep from "./fourthStep/fourthStep";
import Footer from "../../components/Footer/footer";

const HomePage = () => {
  const [pageNum, setPageNum] = useState(0);

  const handleScroll = (index) => {
    scroller.scrollTo(`section${index}`, {
      duration: 800,
      delay: 1,
      smooth: "easeInOutQuart",
    });
    setPageNum(index);
  };

  useEffect(() => {
    const handlePageScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition >= 0 && scrollPosition < window.innerHeight - 200) {
        setPageNum(0);
      } else if (scrollPosition < 2 * window.innerHeight - 200) {
        setPageNum(1);
      } else if (scrollPosition < 3 * window.innerHeight - 200) {
        setPageNum(2);
      } else {
        setPageNum(3);
      }
    };

    window.addEventListener("scroll", handlePageScroll);
    return () => {
      window.removeEventListener("scroll", handlePageScroll);
    };
  }, []);

  return (
    <>
      <Scroll handleScroll={handleScroll} pageNum={pageNum} />
      <Element name="section0">
        <FirstStep />
      </Element>
      <Element name="section1">
        <SecondStep />
      </Element>
      <Element name="section2">
        <ThirdStep />
      </Element>
      <Element name="section3">
        <FourthStep />
      </Element>
      <Footer />
    </>
  );
};

export default HomePage;
