import "./about-page.css";

import Container from "../../components/container/container";
import { Header } from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import { useTranslation } from "react-i18next";

import img1 from "../../images/bio_1.jpg";
import img2 from "../../images/bio_2.jpg";

const AboutPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className="about-page">
        <Container>
          <div className="about_page__block">
            <div className="about-page-bg">
              <div className="about_page__block__content">
                <h1>{t("authorFullName")}</h1>
                {t("AM_bio1")}
                <img
                  src={img1}
                  className="about_page__block__images-container__img first-img"
                  align="right"
                  alt="Vladimir Tereshchuk in the mountains"
                />
              </div>

              <div className="about_page__block__content">{t("AM_bio2")}</div>
              <div className="about_page__block__content">{t("AM_bio3")}</div>
              <div className="about_page__block__content">{t("AM_bio4")}</div>
              <div className="about_page__block__content">{t("AM_bio5")}</div>
              <div className="about_page__block__content">{t("AM_bio6")}</div>
              <div className="about_page__block__content">{t("AM_bio7")}</div>
              <div className="about_page__block__content">{t("AM_bio8")}</div>

              <h2>{t("AM_ExAndAchTitle")}:</h2>

              <div>
                <ul>
                  <li className="about_page__block__list">
                    {t("AM_ExAndAch1")}
                  </li>
                  <li className="about_page__block__list">
                    {t("AM_ExAndAch2")}:
                  </li>
                  <div className="about_page__block__art-competitions">
                    <div>{t("AM_ExAndAch3")}</div>
                    <div>{t("AM_ExAndAch4")}</div>
                  </div>
                  <li className="about_page__block__list">
                    {t("AM_ExAndAch5")}
                  </li>
                  <li className="about_page__block__list">
                    {t("AM_ExAndAch6")}
                  </li>
                </ul>
              </div>

              <img
                src={img2}
                className="about_page__block__images-container__img second-img"
                align="left"
                alt="Vladimir Tereshchuk draws"
              />

              <h2>{t("AM_workCollectionsTitle")}:</h2>
              <ul>
                <li className="about_page__block__list">
                  {t("AM_workCollections1")}
                </li>
                <li className="about_page__block__list">
                  {t("AM_workCollections2")}
                </li>
                <li className="about_page__block__list">
                  {t("AM_workCollections3")}
                </li>
                <li className="about_page__block__list">
                  {t("AM_workCollections4")}
                </li>

                <li className="about_page__block__list">
                  {t("AM_workCollections5")}
                </li>
                <li className="about_page__block__list">
                  {t("AM_workCollections6")}
                </li>
                <li className="about_page__block__list">
                  {t("AM_workCollections7")}
                </li>
                <li className="about_page__block__list">
                  {t("AM_workCollections8")}
                </li>
                <li className="about_page__block__list">
                  {t("AM_workCollections9")}
                </li>
                <li className="about_page__block__list">
                  {t("AM_workCollections10")}
                </li>
                <li className="about_page__block__list">
                  {t("AM_workCollections11")}
                </li>
                <li className="about_page__block__list">
                  {t("AM_workCollections12")}
                </li>
                <li className="about_page__block__list">
                  {t("AM_workCollections13")}
                </li>
                <li className="about_page__block__list">
                  {t("AM_workCollections14")}
                </li>
                <li className="about_page__block__list">
                  {t("AM_workCollections15")}
                </li>
              </ul>
              <h2>{t("AM_mainWorksTitle")}:</h2>
              <ul>
                <li className="about_page__block__list">
                  {t("AM_mainWorks1")}
                </li>
                <li className="about_page__block__list">
                  {t("AM_mainWorks2")}
                </li>
                <li className="about_page__block__list">
                  {t("AM_mainWorks3")}
                </li>
                <li className="about_page__block__list">
                  {t("AM_mainWorks4")}
                </li>
                <li className="about_page__block__list">
                  {t("AM_mainWorks5")}
                </li>
              </ul>

              <h2>{t("AM_mainSeriesTitle")}:</h2>
              <ul>
                <li className="about_page__block__list">
                  {t("AM_mainSeries")}
                </li>
              </ul>
              <h2>{t("AM_illustrationsTitle")}:</h2>
              <ul>
                <li className="about_page__block__list">
                  {t("AM_illustrations")}
                </li>
              </ul>
              <h2>{t("AM_educationTitle")}</h2>
              <ul>
                <li className="about_page__block__list">
                  {t("AM_education1")}
                </li>
                <li className="about_page__block__list">
                  {t("AM_education2")}
                </li>
              </ul>
              <h2>{t("AM_affiliationTitle")}:</h2>
              <ul>
                <li className="about_page__block__list">
                  {t("AM_affiliation")}
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default AboutPage;
