import { Header } from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import Container from "../../components/container/container";
import paintingsData from "../../artData/paintingsData.json";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Contacts from "../../components/contacts/contacts";
import "./art-catalog.css";

const ArtPage = () => {
  const { t } = useTranslation();
  const [widgetVisible, setWidgetVisible] = useState(false);
  const toggleWidget = () => {
    setWidgetVisible(!widgetVisible);
  };
  return (
    <>
      <Header />
      <div className="art">
        {widgetVisible && <Contacts onClose={toggleWidget} />}
        <Container>
          <div style={{ color: "white" }}>
            <div className="for-sale__contacts-block">
              {t("forSale")}
              <a
                className="for-sale__contacts-block__link"
                href="mailto:vlads_art@yahoo.com"
              >
                vlads_art@yahoo.com
              </a>
            </div>
          </div>
          <hr />
          <div className="art__block">
            {/* Проходим по каждому элементу в paintingsData и передаем данные в ArtCard */}
            {paintingsData.map((painting, index) => (
              <ArtCard key={index} painting={painting} />
            ))}
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

const ArtCard = ({ painting }) => {
  const { t, i18n } = useTranslation();
  const initialLanguage = i18n.language;
  const [widgetVisible, setWidgetVisible] = useState(false);
  const toggleWidget = () => {
    setWidgetVisible(!widgetVisible);
  };
  const link = `images/${painting.file}`;

  return (
    <>
      {widgetVisible && <Contacts onClose={toggleWidget} />}
      <div className="art__block__card">
        <Link to={`./${painting.id}`}>
          <img
            className="art__block__card__img"
            src={link}
            alt={t(painting.name[initialLanguage])}
            loading="lazy"
          />
        </Link>
        {painting.forSale && (
          <div className="for-sale">
            <div>
              {t("forSale")}{" "}
              <a
                className="for-sale__contacts-block__link"
                href={`mailto:vlads_art@yahoo.come?subject=${t(
                  "mailSubject"
                )}&body=${
                  `${t("mailBody1")}` +
                  `"${t(painting.name[initialLanguage])}" ` +
                  `${t("mailBody2")}`
                }`}
              >
                vlads_art@yahoo.com
              </a>
            </div>
          </div>
        )}
        <Link to={`./${painting.id}`}>
          <h2 className="art__block__card__title">
            {t(painting.name[initialLanguage])}
          </h2>
          <div className="art__block__card__info">
            <p className="art__block__card__info__size">
              {t("size")}: {painting.size} {t("cm")}
            </p>
            <hr />
            <p className="art__block__card__info__characteristic">
              {t(painting.characteristic[initialLanguage])}
            </p>
            <hr />
            <p className="art__block__card__info__year">
              {t("year")}: {painting.year}
            </p>
            <p className="art__block__card__info__description">
              {t(painting.description[initialLanguage])}
            </p>
          </div>
        </Link>
      </div>
    </>
  );
};

export default ArtPage;
