import "./scroll.css";
import { useTranslation } from "react-i18next";

const Scroll = ({ handleScroll, pageNum }) => {
  const { t } = useTranslation();
  return (
    <div className="scroll">
      <div
        className={`scroll__first-page ${pageNum === 0 ? "active" : ""}`}
        onClick={() => handleScroll(0)}
      ></div>
      <div
        className={`scroll__second-page ${pageNum === 1 ? "active" : ""}`}
        onClick={() => handleScroll(1)}
      ></div>
      <div
        className={`scroll__third-page ${pageNum === 2 ? "active" : ""}`}
        onClick={() => handleScroll(2)}
      ></div>
      <div
        className={`scroll__fourth-page ${pageNum === 3 ? "active" : ""}`}
        onClick={() => handleScroll(3)}
      ></div>
      <div className="scroll__line"></div>
      <div className="scroll__text">{t("scroll")}</div>
    </div>
  );
};

export default Scroll;
