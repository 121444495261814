import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import paintingsData from "../../../artData/paintingsData.json";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Header } from "../../../components/Header/header";
import Footer from "../../../components/Footer/footer";

import Container from "../../../components/container/container";

import "./art-detail-page.css";

//image bg
import bgBlack from "../../../images/bg_art2.jpg";
// import bgBlack from "../../../images/small_bg_art2.jpg";

import bgWhite from "../../../images/bg_art2_white.jpg";
import bgOlive from "../../../images/bg_art2_olive.jpg";
import bgOrange from "../../../images/bg_art2_orange.jpg";

import AOS from "aos";
import "aos/dist/aos.css";
import Contacts from "../../../components/contacts/contacts";

const ArtDetailPage = ({ description, title, ogImage, keywords }) => {
  const { t, i18n } = useTranslation();
  const initialLanguage = i18n.language;
  const [widgetVisible, setWidgetVisible] = useState(false); //виджет контакта при продаже
  const [backgroundColor, setBackgroundColor] = useState("#a8b6bd"); // Начальный цвет фона
  const [backgroundImage, setBackgroundImage] = useState(bgBlack); // Начальный фон

  const toggleWidget = () => {
    setWidgetVisible(!widgetVisible);
  };
  useEffect(() => {
    AOS.init({
      offset: 0,
      duration: 600,
      easing: "ease-in-sine",
      delay: 100,
    });
    AOS.refresh();
  }, []);
  const { id } = useParams();
  const painting = paintingsData.find((item) => item.id === parseInt(id));

  if (!painting) {
    return <div>{t("imgNF")}</div>;
  }

  const handleBgChange = (bg) => {
    setBackgroundImage(bg);
  };

  const handleColorChange = (color) => {
    setBackgroundColor(color);
  };

  const computerStyle = () => {
    if (painting.size_h <= 17) {
      return {
        marginTop: 220,
        width: `${painting.size_w * 8 + 30}px`,
        height: `${painting.size_h * 8 + 30}px`,
        padding: 30,
      };
    } else if (painting.size_h <= 27) {
      return {
        marginTop: 220,
        width: `${painting.size_w * 6 + 30}px`,
        height: `${painting.size_h * 6 + 30}px`,
        padding: 30,
      };
    } else if (painting.size_h <= 30) {
      return {
        marginTop: 220,
        width: `${painting.size_w * 4.5 + 30}px`,
        height: `${painting.size_h * 4.5 + 30}px`,
        padding: 30,
      };
    } else {
      return {
        marginTop: 100,
        width: `${painting.size_w * 3.4}px`,
        height: `${painting.size_h * 3.4}px`,
      };
    }
  };
  const mobileStyle = () => {
    if (painting.size_h <= 80) {
      return {
        marginTop: 150,
        width: `${painting.size_w * 3.5}px`,
        height: `${painting.size_h * 3.5}px`,
      };
    } else {
      return {
        width: `100%`,
        maxWidth: `${painting.size_w * 2.5}px`,
        height: `${painting.size_h * 2.5}px`,
      };
    }
  };

  const getPrevPaintingId = () => {
    const currentIndex = paintingsData.findIndex(
      (item) => item.id === parseInt(id)
    );
    const prevIndex = currentIndex - 1;
    if (prevIndex >= 0) {
      return paintingsData[prevIndex].id;
    }
    return null;
  };

  const getNextPaintingId = () => {
    const currentIndex = paintingsData.findIndex(
      (item) => item.id === parseInt(id)
    );
    const nextIndex = currentIndex + 1;
    if (nextIndex < paintingsData.length) {
      return paintingsData[nextIndex].id;
    }
    return null;
  };

  const prevPaintingId = getPrevPaintingId();
  const nextPaintingId = getNextPaintingId();

  return (
    <>
      {widgetVisible && <Contacts onClose={toggleWidget} />}
      <div className="art-detail-page">
        <Header />
        <div className="art-detail-page__block">
          <div
            className="art-detail-page__block__sofa"
            style={{
              backgroundImage: `url(${backgroundImage})`,
              // eslint-disable-next-line
              backgroundSize: painting.size_h < 17 ? "160vh" : "cover",
              // eslint-disable-next-line
              backgroundSize: painting.size_h < 30 ? "120vh" : "cover",
            }}
          >
            <div className="shadow-for-header"></div>
            <div className="picture-container">
              <div
                className="art-detail-page__block__sofa__container-image"
                style={{
                  backgroundColor: backgroundColor,
                  ...(window.innerWidth <= 840
                    ? mobileStyle()
                    : computerStyle()),
                }}
              >
                <img
                  className="art-detail-page__block__sofa__image"
                  src={`../images/${painting.file}`}
                  alt={painting.name[initialLanguage]}
                />
                <div className="dimensions-info">
                  <p className="dimensions-info__height">
                    {t("height")}: {painting.size_h} {t("cm")}
                  </p>
                  <p className="dimensions-info__width">
                    {t("width")}: {painting.size_w} {t("cm")}
                  </p>
                </div>
                <div className="art-detail-page__block__info__content__links">
                  {prevPaintingId !== null && (
                    <Link
                      className="prev-painting-arrow"
                      to={`/art/${prevPaintingId}`}
                    >
                      {"<"}
                    </Link>
                  )}
                  {nextPaintingId !== null && (
                    <Link
                      className="next-painting-arrow"
                      to={`/art/${nextPaintingId}`}
                    >
                      {">"}
                    </Link>
                  )}
                </div>
              </div>
            </div>

            <div className="change-color">
              {t("frameColor")}:
              <div
                onClick={() => handleColorChange("#907e69")}
                style={{ backgroundColor: "#907e69" }}
              ></div>
              <div
                onClick={() => handleColorChange("#6b483c")}
                style={{ backgroundColor: "#6b483c" }}
              ></div>
              <div
                onClick={() => handleColorChange("#6c7e8d")}
                style={{ backgroundColor: "#6c7e8d" }}
              ></div>
              <div
                onClick={() => handleColorChange("#a8b6bd")}
                style={{ backgroundColor: "#a8b6bd" }}
              ></div>
              <div
                onClick={() => handleColorChange("#333")}
                style={{ backgroundColor: "#333" }}
              ></div>
            </div>
            <div className="change-image">
              {t("changeBg")}:
              <div
                onClick={() => handleBgChange(bgBlack)}
                style={{ backgroundColor: "#383c46" }}
              ></div>
              <div
                onClick={() => handleBgChange(bgWhite)}
                style={{ backgroundColor: "#7d89a1" }}
              ></div>
              <div
                onClick={() => handleBgChange(bgOlive)}
                style={{ backgroundColor: "#4e5544" }}
              ></div>
              <div
                onClick={() => handleBgChange(bgOrange)}
                style={{ backgroundColor: "#6e5131" }}
              ></div>
            </div>
          </div>
          <div className="art-detail-page__block__info">
            <Container>
              <div
                className="art-detail-page__block__info__content"
                data-aos="fade"
              >
                <div className="art-detail-page__block__info__content__links">
                  {prevPaintingId !== null && (
                    <Link
                      className="prev-painting-arrow"
                      to={`/art/${prevPaintingId}`}
                    >
                      {"<"}
                    </Link>
                  )}
                  {nextPaintingId !== null && (
                    <Link
                      className="next-painting-arrow"
                      to={`/art/${nextPaintingId}`}
                    >
                      {">"}
                    </Link>
                  )}
                </div>
                <h2 className="art-detail-page__block__info__content__title">
                  {painting.name[initialLanguage]}
                </h2>
                <img
                  src={`../images/${painting.file}`}
                  alt={painting.name[initialLanguage]}
                />
                <div className="art-detail-page__block__info__content__characteristic">
                  <p>
                    {t("size")}: {painting.size} {t("cm")}
                  </p>
                  <p>{painting.characteristic[initialLanguage]}</p>
                  <p>
                    {t("year")}: {painting.year}
                  </p>
                  <p>{painting.description[initialLanguage]}</p>
                  {painting.forSale && (
                    <div className="for-sale__detail-page">
                      <div title="Click">
                        {t("forSale")}
                        <a
                          className="for-sale__detail-page__email"
                          href={`mailto:vlads_art@yahoo.come?subject=${t(
                            "mailSubject"
                          )}&body=${
                            `${t("mailBody1")}` +
                            `"${t(painting.name[initialLanguage])}" ` +
                            `${t("mailBody2")}`
                          }`}
                        >
                          vlads_art@yahoo.com
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArtDetailPage;
