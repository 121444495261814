import React, { useState } from "react";

import "./header.css";
import Container from "../container/container";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import logoImgBgNone from "../../images/icons/logo1.png";
import logoImg from "../../images/icons/logo.png";

const Logo = (props) => {
  return (
    <Link to="/">
      <div className="logo">
        <img
          className="logo__image"
          src={props.noLogoBg ? logoImgBgNone : logoImg}
          width={props.noLogoBg ? 37 : 60}
          alt="Logo"
        />
        <div className="logo__text">
          vlad's
          <br />
          art
        </div>
      </div>
    </Link>
  );
};

const Header = (props) => {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng); // Сохраняем выбранный язык в localStorage
  };
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <Container>
        <div className="navbar">
          <div className="nav-container" id={menuOpen ? "menu-open" : "menu-close"}>
            <input
              className="checkbox"
              type="checkbox"
              checked={menuOpen}
              onChange={handleMenuToggle}
              id="input-button"
            />
            <div className="hamburger-lines">
              <span className="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
            </div>
          </div>
          <div className="navbar__menu" id={menuOpen ? "menu-open" : "menu-close"}>
            <Link to="/">{t("home")}</Link>
            <Link to="/about">{t("aboutMe")}</Link>
            <Link to="/art">{t("art")}</Link>
            <Link to="/blog">{t("blog")}</Link>
            {props.langs ? null : (
              <div className="header__langs header__langs--menu">
                <div onClick={() => changeLanguage("ru")}>Рус</div>
                <div onClick={() => changeLanguage("en")}>Eng</div>
              </div>
            )}
          </div>
        </div>
        {props.langs ? null : (
          <div className="header__langs header__langs--header">
            <div onClick={() => changeLanguage("ru")}>Рус</div>
            <div onClick={() => changeLanguage("en")}>Eng</div>
          </div>
        )}

        <Logo noLogoBg={props.noLogoBg} />
      </Container>
    </header>
  );
};

export { Header, Logo };
