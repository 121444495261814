import React from "react";
import { Header } from "../../../components/Header/header";
import { useTranslation } from "react-i18next";
import "./first-step.css";
import i18n from "../../../i18n";
import Container from "../../../components/container/container";

const FirstStep = () => {
  const { t } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng); // Сохраняем выбранный язык в localStorage
  };

  return (
    <div className="first-step">
      <Header langs="true" noLogoBg={true} />
      <div className="first-step__content-block">
        
        <div className="first-step__content-block__left-side" id="menuBlock">
          <div className="first-step__content-block__author">
            <div className="first-step__content-block__author__block">
              <i className="first-step__content-block__author__name">
                {t("authorName")}
              </i>
              <div className="first-step__content-block__author__quote">
                <i>{t("FS_quote1")}</i>
                <br />
                <i>{t("FS_quote2")}</i>
              </div>
            </div>
          </div>
          <div className="first-step__content-block__links">
            <a
              href="https://www.instagram.com/vlad_tereshchuk_art/?hl=ru"
              rel="noreferrer"
              target="_blank"
              className="instagram"
            >
              {" "}
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100013268348705"
              rel="noreferrer"
              target="_blank"
              className="facebook"
            >
              {" "}
            </a>
          </div>
          <div className="first-step__content-block__langs">
            <div onClick={() => changeLanguage("ru")}>Рус</div>
            <div onClick={() => changeLanguage("en")}>Eng</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstStep;
