import React from "react";

import { Routes, Route, BrowserRouter } from "react-router-dom";
import HomePage from "./pageComponents/homePage/HomePage";
import ArtPage from "./pageComponents/artCatalog/artCatalog";
import ArtDetailPage from "./pageComponents/artCatalog/artDetailPage/artDetailPage";
import BlogPage from "./pageComponents/blogPage/blogPage";
import AboutPage from "./pageComponents/aboutPage/aboutPage";
import ScrollToTop from "./components/scrollToTop/scrollToTop";
import { Helmet } from "react-helmet";

function App() {
  const pages = [
    {
      path: "/",
      title: "Vlad's art | Home page",
      description: {
        ru: "Добро пожаловать на главную страницу Владимира Алексеевича Терещука, современного белорусского художника. Откройте для себя мир ярких красок, глубоких эмоций и природной красоты через его произведения искусства. Погрузитесь в уникальное художественные произведения. Разнообразие тем и стилей позволит вам ощутить вдохновение в каждом штрихе. Добро пожаловать в мир искусства Владимира Терещука",
        en: "Welcome to the home page of Vladimir Alekseevich Tereshchuk, a contemporary Belarusian artist. Discover the world of bright colors, deep emotions and natural beauty through his works of art. Immerse yourself in a unique artwork. The variety of themes and styles will allow you to feel the inspiration in every stroke. Welcome to the world of art by Vladimir Tereshchuk",
      },
      keywords:
        "oil painting, acrylic painting, original fine art, original oil painting, original oil and acrylic  painting, landscape painting, still life painting, still life painting original, Vladimir Tereshchuk, Vladimir Tereschuk, Uladzimir Tserashchuk, Uladzimir Tereschuk, Uladzimir Tsereshchuk, Expressionist painting, landscape painting, impressionist painting, impressionist still-life, original expressionist landscspe painting, original impressionist oil painting,  spiritual painting, spiritual original oil painting, member of Artist Union, member of Belorussian Artist Union, подарок, эксклюзивный подарок, эксклюзив, gift, exclusive gift, unique gift, Духовная живопись, Союз Художников, член Союза Художников. Spiritual Art, Spirired Art, Spiritual painting, Белорусский художник, известный художник, современный художник, современный белорусский художник, известный современный белорусский художник",
      ogImage: "images/og-image/home.jpg",
      component: HomePage,
    },
    {
      path: "/blog",
      title: "Vlad's art | Blog page",
      description: {
        ru: "Добро пожаловать на страницу блога Владимира Алексеевича Терещука. Здесь вы найдете интересные статьи, анализ современного искусства, вдохновляющие идеи и обсуждения. Погрузитесь в мир искусства и обогатьте свое понимание современных художественных тенденций. Присоединяйтесь к нам в этом увлекательном путешествии в мир искусства.",
        en: "Welcome to Vladimir Alekseevich Tereshchuk's blog page. Here you will find interesting articles, analysis of contemporary art, inspiring ideas and discussions. Immerse yourself in the world of art and enrich your understanding of contemporary art trends. Join us on this exciting journey into the world of art.",
      },
      keywords:
        "art blog, creative insights, artist interviews, art techniques, art inspiration, contemporary art discussions, art news, art events, art analysis, art trends, art commentary, visual storytelling, художественный блог, творческие идеи, интервью с художниками, художественные техники, вдохновение в искусстве, обсуждения современного искусства, новости искусства, художественные события, анализ искусства, тенденции в искусстве, комментарии о произведениях искусства, визуальное повествование.",
      ogImage: "images/og-image/blog.jpg",
      component: BlogPage,
    },
    {
      path: "/art",
      title: "Vlad's art | Art page",
      description: {
        ru: "Исследуйте уникальный каталог произведений искусства Владимира Алексеевича Терещука. Откройте для себя современные интерпретации природы и живописи. Каждая картина - это отдельная история, рассказанная через взгляд художника. Добро пожаловать в виртуальную галерею искусства.",
        en: "Explore Vladimir Alekseevich Tereshchuk's unique catalog of artworks. Discover modern interpretations of nature and painting. Each painting is a separate story told through the artist's perspective. Welcome to the virtual gallery of art.",
      },
      keywords:
        "contemporary art, paintings, acrylic, oil, graphics, poster design, nature, color, creativity, artistic expression, art collection, Belarusian artist, visual storytelling, art catalog, contemporary paintings, fine art, visual arts, original artwork, canvas art, art collection, artistic expression, nature-inspired art, colorful paintings, modern interpretations, acrylic paintings, oil paintings, graphics, poster design, современное искусство, живопись, акрил, масло, графика, дизайн плакатов, природа, цвет, креативность, художественное выражение, коллекция произведений искусства, белорусский художник, визуальное повествование, каталог искусства, современная живопись, изобразительное искусство, оригинальные произведения, холстовое искусство, коллекция произведений искусства, художественное выражение, искусство вдохновленное природой, красочная живопись, современные интерпретации, акриловая живопись, масляная живопись, графика, дизайн плакатов",
      ogImage: "images/og-image/art.jpg",
      component: ArtPage,
    },
    {
      path: "/art/:id",
      title: "Vlad's art | Viewing the picture in interior mode",
      description: {
        ru: "Просмотр в режиме интерьера. Добро пожаловать на страницу произведения искусства Владимира Алексеевича Терещука. Здесь вы можете полностью погрузиться в мир художественного творчества. Откройте перед собой этот уникальный образ, ощутите его глубину и эмоциональное воздействие. Художник передает свои чувства и идеи через каждый штрих, приглашая вас разгадать историю, лежащую за этой картиной. Присмотритесь к деталям, почувствуйте влияние цветов, и откройте новый уровень понимания искусства. Добро пожаловать в увлекательное путешествие в мир вдохновения и художественных открытий.",
        en: "View in interior mode. Welcome to the page of Vladimir Alekseevich Tereshchuk's artwork. Here you can fully immerse yourself in the world of artistic creation. Open before you this unique image, feel its depth and emotional impact. The artist conveys his feelings and ideas through every stroke, inviting you to unravel the story behind this painting. Look closely at the details, feel the impact of the colors, and discover a new level of understanding of art. Welcome to a fascinating journey of inspiration and artistic discovery.",
      },
      keywords:
        "contemporary art, paintings, acrylic, oil, graphics, poster design, nature, color, creativity, artistic expression, art collection, Belarusian artist, visual storytelling, art catalog, contemporary paintings, fine art, visual arts, original artwork, canvas art, art collection, artistic expression, nature-inspired art, colorful paintings, modern interpretations, acrylic paintings, oil paintings, graphics, poster design, современное искусство, живопись, акрил, масло, графика, дизайн плакатов, природа, цвет, креативность, художественное выражение, коллекция произведений искусства, белорусский художник, визуальное повествование, каталог искусства, современная живопись, изобразительное искусство, оригинальные произведения, холстовое искусство, коллекция произведений искусства, художественное выражение, искусство вдохновленное природой, красочная живопись, современные интерпретации, акриловая живопись, масляная живопись, графика, дизайн плакатов",
      ogImage: "images/og-image/art.jpg",
      component: ArtDetailPage,
    },
    {
      path: "/about",
      title: "Vlad's art | About artist",
      description: {
        ru: "Добро пожаловать на страницу Владимира Алексеевича Терещука - современного белорусского художника с богатым опытом искусства. Его живопись отражает яркое восприятие мира искусством. Вдохновленный светом, цветами и красотой природы, Владимир создает произведения, которые переносят нас в уникальный мир художественных взглядов. Изначально родившийся в Бресте, Беларусь, он нашел свой путь в искусстве через дизайн плакатов и пейзажи. Его работы с успехом представлены на выставках и в музейных коллекциях по всему миру. Откройте для себя его профессиональное наследие и страсть к воплощению красоты через искусство.",
        en: "Welcome to the page of Vladimir Alekseevich Tereshchuk - a contemporary Belarusian artist with a rich experience of art. His painting reflects a vivid perception of the world by art. Inspired by light, colors and the beauty of nature, Vladimir creates works that take us into a unique world of artistic views. Originally born in Brest, Belarus, he found his way into art through poster design and landscapes. His work has been successfully featured in exhibitions and museum collections around the world. Explore Discover his professional legacy and passion for realizing beauty through art.",
      },
      keywords:
        "Vladimir Alekseevich Tereshchuk, contemporary Belarusian artist, professional experience, artistic expression, painting, acrylic, oil, graphics, poster design, light, color, nature, biography, art journey, Belarusian art, art studio, art education, exhibitions, achievements, Владимир Алексеевич Терещук, современный белорусский художник, профессиональный опыт, художественное выражение, живопись, акрил, масло, графика, дизайн плакатов, свет, цвет, природа, биография, художественное творчество, белорусское искусство, художественная студия, художественное образование, выставки, достижения",
      ogImage: "images/og-image/about.jpg",
      component: AboutPage,
    },
  ];

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {pages.map((page, index) => (
          <Route
            key={index}
            path={page.path}
            element={
              <div>
                <Helmet>
                  <title>{page.title}</title>
                  <meta
                    name="description"
                    content={page.description.ru}
                    lang="ru"
                  />
                  <meta
                    name="description"
                    content={page.description.en}
                    lang="en"
                  />
                  <meta name="keywords" content={page.keywords} />
                  <meta property="og:title" content={page.title} />
                  <meta
                    property="og:description"
                    content={page.description.en}
                  />
                  <meta property="og:image" content={page.ogImage} />
                </Helmet>
                {React.createElement(page.component)}
              </div>
            }
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
}
export default App;
