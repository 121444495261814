import "./footer.css";
import Container from "../container/container";
import { Logo } from "../Header/header";

import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 300,
      easing: "ease-in-sine",
      delay: 100,
    });
    AOS.refresh();
  }, []);
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer">
      <Container>
        <div className="footer__block" data-aos="fade">
          <Logo />
          <div className="footer__block__column">
            <div className="footer__block__column__title">{t("menu")}:</div>
            <div className="footer__block__column__text">
              <Link to={"/"}>{t("home")}</Link>
              <Link to={"/about"}>{t("aboutMe")}</Link>
              <Link to={"/art"}>{t("art")}</Link>
              <Link to={"/blog"}>{t("blog")}</Link>
            </div>
          </div>
          <div className="footer__block__column">
            <div className="footer__block__column__title">{t("contacts")}:</div>
            <div className="footer__block__column__text">
              <a href="mailto:vlads_art@yahoo.com">vlads_art@yahoo.com</a>
              <a href="tel:+375293994512">+375 (29) 399-45-12</a>
              <div className="footer__block__column__text__links">
                <a
                  href="https://www.instagram.com/vlad_tereshchuk_art/?hl=ru"
                  rel="noreferrer"
                  target="_blank"
                  className="footer__block__column__text__links__instagram"
                >
                  {" "}
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=100013268348705"
                  rel="noreferrer"
                  target="_blank"
                  className="footer__block__column__text__links__facebook"
                >
                  {" "}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__block__copyright">
          &copy; {currentYear} vlads.art {t("copyRight")}.
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
