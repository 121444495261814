import "./contacts.css";
import { useTranslation } from "react-i18next";

const Contacts = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <div className="contacts_widget_bg">
        <div className="contacts_bg--close" onClick={onClose}></div>
      <div className="contacts_widget_block">
        <div
          className="contacts_widget_block__close-button"
          onClick={onClose}
        ></div>
        <div className="contacts_widget_block__content">
          <h2>{t("contacts")}</h2>
          <h3>{t("email")}:</h3>
          <a href="mailto:vlads_art@yahoo.com">vlads_art@yahoo.com</a>
          <h3>{t("socNet")}:</h3>
          <div className="contacts_widget_block__content__links">
            <a
              href="https://www.instagram.com/vlad_tereshchuk_art/?hl=ru"
              rel="noreferrer"
              target="_blank"
              className="contacts_widget_block__content__links__instagram"
            > </a>
            <a
              href="https://www.facebook.com/profile.php?id=100013268348705"
              rel="noreferrer"
              target="_blank"
              className="contacts_widget_block__content__links__facebook"
            > </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
