import "./third-step.css";
import Container from "../../../components/container/container";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const ThirdStep = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 100,
    });
    AOS.refresh();
  }, []);
  return (
    <div className="third-step">
      <div className="third-step__bio" data-aos="fade-right">
        <Container>
          <h1 className="third-step__bio__title">{t("authorName")}</h1>
          <div className="third-step__bio__first-content-block">
            <div className="third-step__bio__first-content-block__img">
              <img src="images/bio.png" alt="Vladimir Tereshchuk" />
            </div>
            <div className="third-step__bio__first-content-block__text">
              <h2 className="third-step__bio__first-content-block__text__title">
                {t("Exhibitions")}
              </h2>
              <div className="third-step__bio__first-content-block__text__content">
                {t("TS_ExhibitionsContent")}
              </div>
            </div>
          </div>
        </Container>
        <div
          className="third-step__bio__second-content-block"
          data-aos="slide-up"
          data-aos-duration="600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
            preserveAspectRatio="none"
          >
            <path
              fill="#111"
              fillOpacity="1"
              d="M0,96L10.9,90.7C21.8,85,44,75,65,74.7C87.3,75,109,85,131,90.7C152.7,96,175,96,196,128C218.2,160,240,224,262,261.3C283.6,299,305,309,327,293.3C349.1,277,371,235,393,202.7C414.5,171,436,149,458,149.3C480,149,502,171,524,197.3C545.5,224,567,256,589,234.7C610.9,213,633,139,655,138.7C676.4,139,698,213,720,229.3C741.8,245,764,203,785,197.3C807.3,192,829,224,851,229.3C872.7,235,895,213,916,170.7C938.2,128,960,64,982,80C1003.6,96,1025,192,1047,218.7C1069.1,245,1091,203,1113,202.7C1134.5,203,1156,245,1178,234.7C1200,224,1222,160,1244,117.3C1265.5,75,1287,53,1309,58.7C1330.9,64,1353,96,1375,106.7C1396.4,117,1418,107,1429,101.3L1440,96L1440,0L1429.1,0C1418.2,0,1396,0,1375,0C1352.7,0,1331,0,1309,0C1287.3,0,1265,0,1244,0C1221.8,0,1200,0,1178,0C1156.4,0,1135,0,1113,0C1090.9,0,1069,0,1047,0C1025.5,0,1004,0,982,0C960,0,938,0,916,0C894.5,0,873,0,851,0C829.1,0,807,0,785,0C763.6,0,742,0,720,0C698.2,0,676,0,655,0C632.7,0,611,0,589,0C567.3,0,545,0,524,0C501.8,0,480,0,458,0C436.4,0,415,0,393,0C370.9,0,349,0,327,0C305.5,0,284,0,262,0C240,0,218,0,196,0C174.5,0,153,0,131,0C109.1,0,87,0,65,0C43.6,0,22,0,11,0L0,0Z"
            ></path>
          </svg>
          <Container>
            <div className="third-step__bio__second-content-block__text">
              <h2 className="third-step__bio__second-content-block__text__title">
                {t("Biography")}
              </h2>
              <div className="third-step__bio__second-content-block__text__content">
                {t("TS_Biography1")}
                <br />
                {t("TS_Biography2")}
                <br />
                {t("TS_Biography3")}
                <br />
                {t("TS_Biography4")}
              </div>
              <div className="third-step__button">
                <Link to={"/about"}>{t("readMore")}</Link>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ThirdStep;
